@import "simpledotcss/simple.min.css";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

html, body {
    overscroll-behavior: none;
}

body {
    margin: 0 auto;
    font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header {
    padding: .5rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

nav a.active {
    color: var(--accent) !important;
    border-color: var(--accent);
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.nav-bar .actions {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-end;
    gap: 0 1rem;
}

.comms-forms {
    display: flex;
    gap: 2rem
}

.container {
    max-width: 37em;
}

.container > * {
    margin-block-end: 3em;
}

.global-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 2.5em;
}

.global-controls > * {
    flex: 1;
}

.turnouts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.8em 1em;
}

.loco, .turnout {
    display: grid;
    grid-auto-rows: 2.5em;
    grid-gap: 0.3em;
}

.loco {
    grid-template-columns: repeat(8, 1fr);
}

.turnout {
    grid-template-columns: 5em repeat(2, 1fr);
}

.loco > .name {
    grid-column: auto / span 5;
    display: flex;
    align-items: center;
}

.turnout > .id {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline-end: 1em;
}

.turnout > .name {
    grid-column: auto / span 2;
    display: flex;
    align-items: center;
}

.turnout_position_control {
    grid-column: 2 / span 2;
}

.turnout_position {
    margin: 0;
}

.p-0 {
    padding: 0;
}

.m-0 {
    margin: 0;
}

.name > h2 {
    padding: 0;
    margin: 0;
}

.speed {
    grid-column: 1 /span 5;

    display: flex;
    align-items: center;
    justify-content: center;
}

button, select, input {
    width: 100%;
    height: 100%;
}

.direction {
    grid-column: auto / span 2;
}

.view, .e-stop {
    grid-column: 7 / span 2;
}

.functions {
    display: grid;
    grid-column: 1 / span 5;
    grid-template-columns: subgrid;
}

.functions button:enabled[value="1"],
button:enabled[value="1"].turnout_position {
    opacity: 0.8;
}

.loco button {
    padding: 0;
}

.loco button, .loco input {
    margin: 0;
}

@supports not (display: subgrid) {
    .functions {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.3em;
    }
}

.more {
    grid-column-end: -1;
}
